// import store
import store from "../store/index";

// POST api/v1/masterlist/create
const masterListCreate = async (name: string, description: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/masterlist/create`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                description: description
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/masterlist/readpretty/:masterListId
const masterListReadPretty = async (masterListId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/masterlist/readpretty/${masterListId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateMasterListCache", {
                masterList: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateMasterListCache", {
                masterList: null
            });
            // return
            return false;
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateMasterListCache", {
            masterList: null
        });
        // return
        return false;
    }
};
// GET api/v1/masterlist/readallpretty
const masterListReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/masterlist/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateMasterListCache", {
                masterListList: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateMasterListCache", {
                masterListList: []
            });
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateMasterListCache", {
            masterListList: []
        });
        // return
        return [];
    }
};
// POST api/v1/masterlist/create
const masterListAddWord = async () => {

};
// POST api/v1/masterlist/sendto
const masterListSendTo = async (masterListId: number, actionType: number, actionDestination: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/masterlist/sendto`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                masterListId: masterListId,
                actionType: actionType,
                actionDestination: actionDestination
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    masterListCreate,
    masterListReadPretty,
    masterListReadAll,
    masterListAddWord,
    masterListSendTo
}