
import { masterListReadPretty, masterListSendTo } from "@/apiClient/masterListEndpoints";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

// import API

@Component
export default class MasterListView extends Vue {

    private masterListId?: number;
    private masterList: any = null;
    private inputName: string = "";
    private inputDescription: string = "";
    private actionType: number = 0;
    private actionDestination: string = "";

    private sending: boolean = false;

    // MOUNTED
    public async mounted() {
        console.log("mounted");
        if (this.$store.state.masterListCache.masterListId) {
            this.masterList = await masterListReadPretty(this.$store.state.masterListCache.masterListId);
            this.inputName = this.$store.state.masterListCache.masterList.inputName;
            this.inputDescription = this.$store.state.masterListCache.masterList.inputDescription;
        }
    }

    @Watch('$store.state.masterListCache.masterListId')
    public internalUpdate(newValue: number, oldValue: number) {
        masterListReadPretty(newValue).then((masterList) => {
            this.masterList = masterList;
            this.masterListId = masterList.id;
            this.inputName = masterList.name;
            this.inputDescription = masterList.description;
            // scroll up
            window.scrollTo(0,0);
            console.log("safe");
        });
    }

    @Watch('$store.state.masterListCache.masterList')
    public internalUpdateMore(newValue: number, oldValue: number) {
        masterListReadPretty(newValue).then((masterList) => {
            window.scrollTo(0,0);
        });
    }

    public setActionType(actionType: number) {
        this.actionDestination = "";
        this.actionType = actionType;
        this.$forceUpdate();

        const ss = async () => {
            let x = 100;
            if (x >= 0){

            }
        }
    }

    public async sendOffList() {
        this.sending = true;
        try {
            console.log(this.masterListId, this.actionType, this.actionDestination);
            // @ts-ignore
            await masterListSendTo(parseInt(this.masterListId), this.actionType, this.actionDestination);
        }
        catch (error) {
            console.log(error);
        }
        this.sending = false;
    }

    

}
